import React, { useState, useEffect, useReducer, useCallback } from 'react';
import Input from '../components/UI/Input';
import { useDispatch, useSelector } from 'react-redux';
import { updateMe, get_sub_end_date } from '../utils/mmc-api/api';
import Spinner from '../components/UI/Spinner';
import { fetchUser } from '../store/user';


const FORM_INPUT_UPDATE = 'FORM_INPUT_UPDATE';
const formReducer = (state, action) => {
  if (action.type === FORM_INPUT_UPDATE) {
    const updatedValues = {
      ...state.inputValues,
      [action.input]: action.value,
    };
    const updatedValidities = {
      ...state.inputValidities,
      [action.input]: action.isValid,
    };
    let updatedFormIsValid = true;
    for (const key in updatedValidities) {
      updatedFormIsValid = updatedFormIsValid && updatedValidities[key];
    }
    return {
      formIsValid: updatedFormIsValid,
      inputValidities: updatedValidities,
      inputValues: updatedValues,
    };
  }
  return state;
};

const Profile = ({ user }) => {

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const storeUser = useSelector(state => state.user);
  const [subscriptionEndDate, setSubscriptionEndDate] = useState();
  const [formState, dispatchFormState] = useReducer(formReducer, {
    inputValues: {
      fullname: user?.fullname,
      email: user?.email,
      address: user?.address,
      town: user?.town,
      postcode: user?.postcode,

    },
    inputValidities: {
      fullname: !!user?.fullname,
      email: !!user?.email,
      address: !!user?.address,
      town: !!user?.town,
      postcode: !!user?.postcode,

    },
    formIsValid:
      !!user?.fullname && !!user?.email && !!user?.address && !!user?.town && !!user?.postcode,
  });

  const updateHandler = (e)=> {
    e.preventDefault();
    if (formState.formIsValid) {
      setIsLoading(true);
      updateMe(formState.inputValues).then(()=>{
        setIsLoading(false);
        alert('Profile data successfully updated');
        dispatch(fetchUser());
      });
    }
    
  }

  const inputChangeHandler = useCallback(
    (inputIdentifier, inputValue, inputValidity) => {
      dispatchFormState({
        type: FORM_INPUT_UPDATE,
        value: inputValue,
        isValid: inputValidity,
        input: inputIdentifier,
      });
    },
    [dispatchFormState],
  );

  useEffect(() => {
    if (error) {
      Alert.alert('An error occured', error, [{ text: 'Ok' }]);
    }
  }, [error]);

  useEffect(() => {
    const get_end_date = async () => {
        const response = await get_sub_end_date();
        if (response.email || !response) return;
        setSubscriptionEndDate(response);
    }
    if (storeUser) {
        get_end_date();
    }
  }, [storeUser]);
   
  return (
    <div>
      <div className= "profile-user-form" >
        {isLoading ? <Spinner /> : (
            <>
                {subscriptionEndDate && (
                    <p>
                        Your membership renews on: {new Date(subscriptionEndDate).toDateString()}.
                        Please refer to your invoice to cancel your membership or reach out at <a href="mailto:francesca@mymathscloud.com">francesca@mymathscloud.com</a>.
                    </p>
                )}
                <form>
                <Input
                    id="fullname"
                    label="Full Name"
                    required
                    type="text"
                    autoCapitalize="words"
                    errorText="Please enter your full name."
                    onInputChange={inputChangeHandler}
                    initialValue={user ? user.fullname : ''}
                    initiallyValid={!!user?.fullname}
                    updatedValue={user ? user.fullname : ''}
                />
                <Input
                    id="email"
                    label="Email"
                    required
                    type="email"
                    email
                    errorText="Please enter your email address."
                    onInputChange={inputChangeHandler}
                    initialValue={user ? user.email : ''}
                    initiallyValid={!!user?.email}
                    updatedValue={user ? user.email : ''}
                    disabled
                />
                <Input
                    id="address"
                    label="Address"
                    required
                    type="text"
                    errorText="Please enter your address."
                    onInputChange={inputChangeHandler}
                    initialValue={user ? user?.address : ''}
                    initiallyValid={!!user?.address}
                    updatedValue={user ? user?.address : ''}
                />
                <Input
                    id="town"
                    label="Town"
                    required
                    type="text"
                    errorText="Please enter your town."
                    onInputChange={inputChangeHandler}
                    initialValue={user ? user?.town : ''}
                    initiallyValid={!!user?.town}
                    updatedValue={user ? user?.town : ''}
                />
                <Input
                    id="postcode"
                    label="Postcode"
                    required
                    type="text"
                    errorText="Please enter your postcode."
                    onInputChange={inputChangeHandler}
                    initialValue={user ? user?.postcode : ''}
                    initiallyValid={!!user?.postcode}
                    updatedValue={user ? user?.postcode : ''}
                />
                <button className="update-profile-button" onClick={updateHandler}>Update profile details</button>
                </form>
            </>
            )}
        </div>
    </div>
  )
}

export default Profile